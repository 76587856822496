const expData = [
   
    {
      id: "1",
      org: "Google",
      title: "Student Research Intern",
      date: "May 2021 - Dec 2021",
      description:  
          "Conducted an extensive research on Machine Translation for low-resource languages where there are limited available data with available parallel sentences that are highly disorganized. Achieved in selecting the best parallel sentences that can result in high quality Machine Translation (MT) outputs.",
    },  
    {
        id: "2",
        org: "Masakhane",
        title: "NLP Researcher",
        date: "Oct. 2019 - Present",
        description:  
            "Masakhane is a grassroots organisation whose mission is to strengthen and spur NLP research in African languages, for Africans, by Africans.   Contributions: Developed English-Amharic/Amharic-English MachineTranslation baseline using Joey NMT.",
      },  
      {
        id: "3",
        org: "INSA",
        title: "Numerical Developer",
        date: "2014 - 2018",
        description:  
            "Designed and developed an Agent to extract and collect eventlog from multiple data sources. Designed and developed an Aggregator to parse, normalize, insert into database, cache and prepare the eventlogs for behavioral analysis",
      },  
    
];

export default expData;